import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/authSlice";
import usersReducer from "../features/usersSlice";
import countriesReducer from "../features/countriesSlice";
import emailTemplateReducer from "../features/emailTemplateSlice";
import rolesReducer from "../features/rolesSlice";
import dialerReducer from "../features/dialerSlice";
import inboundGroupReducer from "../features/inboundGroupSlice";
import campaignReducer from "../features/campaignSlice";
import didReducer from "../features/didSlice";
import generalSettingReducer from "../features/generalSettingSlice";
import callCenterReducer from "../features/callCentersSlice";
import leadSourceSlice from "../features/leadSourceSlice";
import leadSourceGroupSlice from "../features/leadSourceGroupSlice";
import userGroupSlice from "../features/userGroupSlice";
import leadStatusSlice from "../features/leadStatusSlice";
import leadActionSlice from "../features/leadActionSlice";
import distributionCategorySlice from "../features/distributionCategorySlice";
import distributionProgramSlice from "../features/distributionProgramSlice";
import calendarEventSlice from "../features/calendarEventSlice";
import workflowStatusSlice from "../features/workflowStatusSlice";
export const store = configureStore({
  reducer: {
    auth: authReducer,
    users: usersReducer,
    countries: countriesReducer,
    emailTemplate: emailTemplateReducer,
    roles: rolesReducer,
    dialer: dialerReducer,
    inbound: inboundGroupReducer,
    campaigns: campaignReducer,
    dids: didReducer,
    generalSetting: generalSettingReducer,
    callCenters: callCenterReducer,
    leadSource: leadSourceSlice,
    leadSourceGroup: leadSourceGroupSlice,
    userGroups: userGroupSlice,
    leadStatus: leadStatusSlice,
    leadAction: leadActionSlice,
    distributionCategory: distributionCategorySlice,
    distributionProgram: distributionProgramSlice,
    calendarEvents: calendarEventSlice,
    workflow: workflowStatusSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});
