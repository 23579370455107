import { toast } from "react-toastify";

function errorMessage({ payload, setErrors, action, msg }) {
  if (Array.isArray(payload)) {
    setErrors&&setErrors(payload);
  } else {
    setErrors&&setErrors([]);
    toast.error(payload ? payload : `${action} couldn't be ${msg}`);
  }
}

export default errorMessage;
