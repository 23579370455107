import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  createCampaigns,
  createDispositions,
  createpauseCodes,
  deleteCampaigns,
  deleteDispositions,
  deletepauseCodes,
  getCampaigns,
  getDispositionDrd,
  getDispositions,
  getPauseCodeDrd,
  getpauseCodes,
  updateCampaigns,
  updateDispositions,
  updatepauseCodes,
} from "../services/campaignService";
const initialState = {
  isError: false,
  isLoading: false,
  record: [],
  message: "",
  dispositions: [],
  pause_codes: [],
  dispoDrd: [],
  pauseDrd: [],
};
const getErrorMessage = (error) => {
  return (
    error?.response?.data?.error ||
    (error?.response?.data?.debugInfo && [error?.response?.data?.debugInfo]) ||
    error?.response?.data?.message ||
    error?.response?.data ||
    error.toString()
  );
};
// Get Campaigns Data
export const get_campaigns = createAsyncThunk(
  "get_campaigns",
  async (id, thunkAPI) => {
    try {
      return await getCampaigns();
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Create New Campaigns
export const create_campaigns = createAsyncThunk(
  "create_campaigns",
  async (data, thunkAPI) => {
    try {
      return await createCampaigns(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update Campaigns
export const update_campaigns = createAsyncThunk(
  "update_campaigns",
  async (data, thunkAPI) => {
    try {
      return await updateCampaigns(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Delete Campaigns
export const delete_campaigns = createAsyncThunk(
  "delete_campaigns",
  async (id, thunkAPI) => {
    try {
      return await deleteCampaigns(id);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get Dispositions Data
export const get_dispositions = createAsyncThunk(
  "get_dispositions",
  async (id, thunkAPI) => {
    try {
      return await getDispositions(id);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Create New Dispositions
export const create_dispositions = createAsyncThunk(
  "create_dispositions",
  async (data, thunkAPI) => {
    try {
      return await createDispositions(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update Dispositions
export const update_dispositions = createAsyncThunk(
  "update_dispositions",
  async (data, thunkAPI) => {
    try {
      return await updateDispositions(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Delete Dispositions
export const delete_dispositions = createAsyncThunk(
  "delete_dispositions",
  async (id, thunkAPI) => {
    try {
      return await deleteDispositions(id);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get Pause Codes
export const get_pause_codes = createAsyncThunk(
  "get_pause_codes",
  async (id, thunkAPI) => {
    try {
      return await getpauseCodes(id);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Create New Pause Codes
export const create_pause_codes = createAsyncThunk(
  "create_pause_codes",
  async (data, thunkAPI) => {
    try {
      return await createpauseCodes(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update Pause Codes
export const update_pause_codes = createAsyncThunk(
  "update_pause_codes",
  async (data, thunkAPI) => {
    try {
      return await updatepauseCodes(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Delete Pause Codes
export const delete_pause_codes = createAsyncThunk(
  "delete_pause_codes",
  async (id, thunkAPI) => {
    try {
      return await deletepauseCodes(id);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Get Dispositions Drd
export const get_dispo_drd = createAsyncThunk(
  "get_dispo_drd",
  async (id, thunkAPI) => {
    try {
      return await getDispositionDrd(id);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Get Pause Codes drd
export const get_pause_drd = createAsyncThunk(
  "get_pause_drd",
  async (id, thunkAPI) => {
    try {
      return await getPauseCodeDrd(id);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const campaignSlice = createSlice({
  name: "inboundGroup",
  initialState,
  reducers: {
    campaignReset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.record = [];
      state.message = "";
    },
    updateActiveAgents: (state, action) => {
      state.record = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_campaigns.pending, (state) => {
        state.isLoading = true;
        state.record = [];
      })
      .addCase(get_campaigns.fulfilled, (state, action) => {
        state.isLoading = false;
        state.record = action.payload?.data;
      })
      .addCase(get_campaigns.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.record = [];
        toast.error(action.payload ? action.payload : "Unknown Error");
      })
      .addCase(create_campaigns.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(create_campaigns.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(create_campaigns.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_campaigns.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_campaigns.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_campaigns.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(delete_campaigns.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(get_dispositions.pending, (state) => {
        state.isLoading = true;
        state.dispositions = [];
      })
      .addCase(get_dispositions.fulfilled, (state, action) => {
        state.isLoading = false;
        state.dispositions = action.payload?.data;
      })
      .addCase(get_dispositions.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.dispositions = [];
        toast.error(action.payload ? action.payload : "Unknown Error");
      })
      .addCase(create_dispositions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(create_dispositions.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(create_dispositions.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_dispositions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_dispositions.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_dispositions.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(delete_dispositions.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(get_pause_codes.pending, (state) => {
        state.isLoading = true;
        state.pause_codes = [];
      })
      .addCase(get_pause_codes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.pause_codes = action.payload?.data;
      })
      .addCase(get_pause_codes.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.pause_codes = [];
        toast.error(action.payload ? action.payload : "Unknown Error");
      })
      .addCase(create_pause_codes.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(create_pause_codes.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(create_pause_codes.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_pause_codes.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_pause_codes.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_pause_codes.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(delete_pause_codes.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(get_dispo_drd.fulfilled, (state, action) => {
        state.isLoading = false;
        state.dispoDrd = action.payload?.data;
      })
      .addCase(get_dispo_drd.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.dispoDrd = [];
        toast.error(action.payload ? action.payload : "Unknown Error");
      })
      .addCase(get_pause_drd.fulfilled, (state, action) => {
        state.isLoading = false;
        state.pauseDrd = action.payload?.data;
      })
      .addCase(get_pause_drd.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.pauseDrd = [];
        toast.error(action.payload ? action.payload : "Unknown Error");
      });
  },
});

export const { campaignReset, updateActiveAgents } = campaignSlice.actions;
export default campaignSlice.reducer;
