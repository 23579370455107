import api from "./api";

export const createEmailTemplate = async (values) => {
  return await api.post("/api/email_templates", values);
};

export const getEmailTemplates = async (data) => {
  return await api.get("/api/email_templates",{params:data});
};

export const updateEmailTemplate = async (values) => {
  return await api.put("/api/email_templates", values);
};

export const deleteEmailTemplate = async (id) => {
  return await api.delete("/api/email_templates", { data: { id } });
};
