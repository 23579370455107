import api from "./api";

export const getCampaigns = async () => {
  return await api.get(`/api/cc/compaigns`);
};
export const createCampaigns = async (data) => {
  return await api.post("/api/cc/compaigns", data);
};
export const updateCampaigns = async (data) => {
  return await api.put("/api/cc/compaigns", data);
};
export const deleteCampaigns = async (id) => {
  return await api.delete("/api/cc/compaigns", { data: { id } });
};

export const getDispositions = async (id) => {
  return await api.get(`/api/cc/compaigns/get_despositions/${id}`);
};
export const createDispositions = async (data) => {
  return await api.post("/api/cc/compaigns/disposition", data);
};
export const updateDispositions = async (data) => {
  return await api.put("/api/cc/compaigns/disposition", data);
};
export const deleteDispositions = async (data) => {
  return await api.delete(`/api/cc/compaigns/disposition/${data?.campaign_id}/${data?.status}`);
};

export const getpauseCodes = async (id) => {
  return await api.get(`/api/cc/compaigns/get_pause_codes/${id}`);
};
export const createpauseCodes = async (data) => {
  return await api.post("/api/cc/compaigns/pause_code", data);
};
export const updatepauseCodes = async (data) => {
  return await api.put("/api/cc/compaigns/pause_code", data);
};
export const deletepauseCodes = async (data) => {
  return await api.delete(`/api/cc/compaigns/pause_code/${data?.campaign_id}/${data?.pause_code}`);
};
export const getDispositionDrd = async (id) => {
  return await api.get(`/api/vicidial/campaign_despositions/${id}`);
};
export const getPauseCodeDrd = async (id) => {
  return await api.get(`/api/vicidial/campaign_pause_codes/${id}`);
};