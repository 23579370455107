import React, { useState } from "react";
import { Input, Button, Loader } from "../../components";
import { new_logo } from "../../images";
import { new_login_bg } from "../../images";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { send_reset_email } from "../../features/authSlice";
const ForgotPassword = () => {
  const dispatch = useDispatch();
  const { isLoading, isError, message, isSuccess } = useSelector(
    (state) => state.auth
  );

  const [username, setUsername] = useState("");

  const submitHandler = async (event) => {
    event.preventDefault();
    dispatch(send_reset_email({ email: username }));
  };

  return (
    <div className="h-screen w-full font-mont flex justify-center items-center">
      <div className="w-full h-screen bg-[#FCC81C] !text-center hidden md:flex justify-center">
        <img src={new_login_bg} className="inline-block" alt="bg_ing" />
      </div>
      <div className="w-full md:w-[600px] max-w-[95%] bg-white flex flex-col justify-  p-4  text-black h-screen">
        <div className="flex w-full justify-center items-center !mb-2">
          <img src={new_logo} alt="login logo" />
        </div>
        <form onSubmit={submitHandler} className="mt-28">
          <div className="relative">
            <label className="mb-1">Email</label>
            <Input
              id="email"
              placeholder="Email"
              className="bg-white text-[#38015c] border border-[#d7d7d7] w-[98%] text-left py-3 px-2.5 h-[60px]"
              value={username}
              onChange={({ target: { value } }) => setUsername(value)}
              name="email"
            />
            <div className="absolute top-11 right-3.7 text-lg text-[#7c7f82]">
              <FontAwesomeIcon icon={faEnvelope} />
            </div>
          </div>
          {isError || isSuccess ? (
            <div
              className={
                isError
                  ? "text-[#f00] text-sm mb-3.7 mt-1.5"
                  : "text-green text-sm mb-3.7 mt-1.5"
              }
            >
              {message}
            </div>
          ) : null}
          <div className="text-black mb-6 flex mt-1.5 px-2.5 justify-center">
            <NavLink
              to={`/login`}
              className="text-primary-100 text-base underline"
            >
              I remembered my password!
            </NavLink>
          </div>
          <div>
            {!isLoading && (
              <Button
                text="Reset Password"
                type="submit"
                className="!w-full !rounded-lg bg-primary-100 text-white cursor-pointer !p-4 text-xl font-normal uppercase w-[98%]"
              />
            )}
            {isLoading && <Loader />}
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
