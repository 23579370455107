import React from "react";
import Loader from "../Loader";

const Button = ({
  variant = "btn_submit",
  className = "",
  disabled = false,
  isLoading = false,
  onClick,
  type = "button",
  backgroundColor,
  color,
  text = "",
  children,
  ...props
}) => {
  const variantClasses = {
    btn_submit:
      "py-1.5 !px-3 align-middle bg-primary-100 border-primary-100 !text-white font-semibold disabled:bg-[#ffd966]",
    btn_cancel:
      "py-1.5 !px-3 align-middle !bg-menu border-menu !text-white font-semibold",
    btn_danger:
      "py-1.5 !px-3 align-middle !bg-danger border-danger !text-white font-semibold",
  };

  const variantClass = variantClasses[variant] || "";

  return (
    <button
      {...props}
      className={`${className} ${variantClass} rounded-none`}
      disabled={disabled || isLoading}
      onClick={onClick}
      type={type}
      style={{
        backgroundColor,
        color,
        fontWeight: 600,
      }}
    >
      {isLoading ? (
        <div className="py-1.5 !px-3">
          <Loader size={20} width={20} height={20} color="#fff" />
        </div>
      ) : (
        text || children
      )}
    </button>
  );
};

export default Button;
