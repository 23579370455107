import React from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
const CustomDropdown = ({
  options,
  placeholder,
  value,
  label,
  name,
  onChange,
  valueProp,
  labelProp,
}) => {
  return (
    <div className="inline-flex text-left p-1 btnSecondary text-menu h-8 flex items-center">
      <ArrowDropDownIcon
        className={`cursor-pointer mr-1 ${value ? "" : "text-[#888888]"}`}
        size={18}
        fontSize="large"
      />
      <select
        className={`appearance-none bg-transparent borde py2 w-40 p-0.5 cursor-pointer ${
          value ? "" : "text-[#888888]"
        }`}
        name={name}
        onChange={(e) => onChange(e)}
        value={value}
      >
        <option className="text-[#888888]" value={""}>{placeholder}</option>
        {options.map((item) => (
          <option value={item[valueProp]}>{item[labelProp]}</option>
        ))}
      </select>
    </div>
  );
};

export default CustomDropdown;
