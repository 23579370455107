import api from "./api";

export const getDistributionProgram = async () => {
  return await api.get("/api/distribution_program");
};
export const createDistributionProgram = async (data) => {
  return await api.post("/api/distribution_program", data);
};

export const updateDistributionProgram = async (data) => {
  return await api.put(`/api/distribution_program/${data?._id}`, data);
};

export const deleteDistributionProgram = async (id) => {
  return await api.delete(`/api/distribution_program/${id}`);
};

export const getUserProgramLead = async (id) => {
  return await api.get(`/api/user_program_lead_count/${id}`);
};

export const getProgramUserLead = async (id) => {
  return await api.get(`/api/user_program_lead_count/program_for_user_id/${id}`);
};

export const createUserProgramLeadCount = async (data) => {
  return await api.post("/api/user_program_lead_count", data);
};

export const createUserProgramLeadCountBulk = async (data) => {
  return await api.post("/api/user_program_lead_count/bulk", data);
};

export const updateUserProgramLeadCount = async (data) => {
  return await api.put(`/api/user_program_lead_count/${data?._id}`, data);
};
