import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createUserGroup,
  deleteUserGroup,
  getUserGroups,
  updateUserGroup,
} from "../services/userGroupService";

const initialState = {
  isError: false,
  isLoading: false,
  record: [],
  message: "",
  isLoader: false,
};
const getErrorMessage = (error) => {
  return (
    error?.response?.data?.error ||
    (error?.response?.data?.debugInfo && [error?.response?.data?.debugInfo]) ||
    error?.response?.data?.message ||
    error?.response?.data ||
    error.toString()
  );
};
// Get Users Data
export const get_user_groups = createAsyncThunk(
  "get_user_groups",
  async (data, thunkAPI) => {
    try {
      return await getUserGroups(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Create New User
export const create_user_group = createAsyncThunk(
  "create_user_group",
  async (data, thunkAPI) => {
    try {
      return await createUserGroup(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update User
export const update_user_group = createAsyncThunk(
  "update_user_group",
  async (data, thunkAPI) => {
    try {
      return await updateUserGroup(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Delete User
export const delete_user_group = createAsyncThunk(
  "delete_user_group",
  async (id, thunkAPI) => {
    try {
      return await deleteUserGroup(id);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const userGroupSlice = createSlice({
  name: "user_groups",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.users = [];
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_user_groups.pending, (state) => {
        state.isLoading = true;
        state.isLoader = true;
        state.record = [];
      })
      .addCase(get_user_groups.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isLoader = false;
        state.record = action.payload?.data;
      })
      .addCase(get_user_groups.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoader = false;
        state.isError = true;
        state.message = action.payload;
        state.record = [];
      })
      .addCase(create_user_group.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(create_user_group.fulfilled, (state, action) => {
        state.isLoading = false;
        state.record.push(action.payload.data);
      })
      .addCase(create_user_group.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_user_group.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_user_group.fulfilled, (state, action) => {
        state.isLoading = false;
        const result = state.record.findIndex(
          ({ _id }) => _id === action?.payload?.data?._id
        );
        state.record[result] = {
          ...state.record[result],
          ...action.payload.data,
        };
      })
      .addCase(update_user_group.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(delete_user_group.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(delete_user_group.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(delete_user_group.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export const { reset } = userGroupSlice.actions;
export default userGroupSlice.reducer;
