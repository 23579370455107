import dayjs from "dayjs";
import userPermission from "./userPermission";

export const getFullName = (record) => {
  const fullName = `${record?.first_name || ""} ${record?.last_name || ""}`;
  if (fullName && fullName !== " ") {
    return fullName;
  } else {
    return "";
  }
};

export const getFormattedDate = (date, format) => {
  if (!date) return "";
  return dayjs(date).format(format ? format : "ddd, MMM D, YYYY h:mm:ss A");
};

export const getFormattedAddress = (shipping_address) => {
  const { address, city, state, zip } = shipping_address || {};
  const addressComponents = [address, city, state, zip].filter(Boolean);
  return addressComponents.join(", ");
};

export const maskPhoneNumber = (phoneNumber) => {
  if (
    userPermission("Show Phone Number") ||
    userPermission("Change Client Phone Number") ||
    !phoneNumber
  )
    return phoneNumber;
  const strPhoneNumber = phoneNumber.toString();
  return "******" + strPhoneNumber.slice(-4);
};

export const removeLeadingOne = (input) => {
  if (!input) return null;
  let str = input.toString();
  if (str.startsWith("+1")) {
    str = str.slice(2);
  } else if (str.startsWith("1")) {
    str = str.slice(1);
  }
  return str;
};
