import React, { useState, useEffect, useRef } from "react";
import "react-quill/dist/quill.snow.css";

const MyEditor = ({ inputValue, onChange }) => {
  const [htmlValue, setHtmlValue] = useState(inputValue);
  const quillRef = useRef(null);

  useEffect(() => {
    setHtmlValue(inputValue);
    if (quillRef.current) {
      const quill = quillRef.current.getEditor();
      quill.clipboard.dangerouslyPasteHTML(inputValue);
    }
  }, [inputValue]);

  const handleHtmlChange = (e) => {
    const newValue = e.target.value;
    setHtmlValue(newValue);
    onChange(newValue);

  };

  return (
    <div>
      {/* <ReactQuill
        ref={quillRef}
        value={value}
        onChange={handleQuillChange}
        modules={options}
        formats={MyEditor.formats}
        theme="snow"
      /> */}
      <div className="mt-4 p-4 border rounded">
        <textarea
          value={htmlValue}
          onChange={handleHtmlChange}
          rows="10"
          className="w-full border p-2"
        />
      </div>
      <div dangerouslySetInnerHTML={{ __html: htmlValue }} />

    </div>
  );
};

MyEditor.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "color",
  "background",
  "script",
  "list",
  "bullet",
  "indent",
  "align",
  "link",
  "image",
  "video",
  "blockquote",
  "code-block",
  "code",
];

export default MyEditor;
