import React from "react";

const TextInput = ({
  type = "text",
  placeholder,
  value,
  id,
  onChange,
  onBlur,
  className,
  name,
  disabled,
  onClick,
  errors,
}) => {
  let classes = className + " " || "";
  return (
    <div onClick={onClick}>
      <input
        name={name}
        type={type}
        id={id}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        className={classes}
        disabled={disabled}
        style={!!onClick ? { cursor: "pointer" } : {}}
      />

      {errors?.length > 0 &&
        errors?.map((error, index) => {
          return (
            <>
              {error[name] && (
                <div key={index} className="text-[#f00] text-sm mb-3.7 mt-1.5">
                  {error[name].replaceAll(name, placeholder)}
                </div>
              )}
            </>
          );
        })}
    </div>
  );
};

export default TextInput;
