import api from "./api";

export const getLeadSource = async (data) => {
  return await api.post(`/api/lead_source`,data);
};
export const createLeadSource = async (data) => {
  return await api.post("/api/lead_source/add_lead_source", data);
};
export const updateLeadSource = async (data) => {
  return await api.put(`/api/lead_source/${data?._id}`, data);
};
export const deleteLeadSource = async (id) => {
  return await api.delete(`/api/lead_source/${id}`);
};
