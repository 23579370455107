import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createRole, getRoles, updateRole } from "../services/rolesService";

const initialState = {
  isError: false,
  isLoading: false,
  record: [],
  message: "",
};
const getErrorMessage = (error) => {
  return (
    error?.response?.data?.error || 
    (error?.response?.data?.debugInfo&&[error?.response?.data?.debugInfo]) ||
    error?.response?.data?.message ||
    error?.response?.data ||
    error.toString()
  );
};
// Get Users Roles
export const get_roles = createAsyncThunk("get_roles", async (thunkAPI) => {
  try {
    return await getRoles();
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Create New Roles
export const create_role = createAsyncThunk(
  "create_role",
  async (data, thunkAPI) => {
    try {
      return await createRole(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update Roles
export const update_role = createAsyncThunk(
  "update_role",
  async (data, thunkAPI) => {
    try {
      return await updateRole(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const rolesSlice = createSlice({
  name: "userRoles",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.record = [];
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_roles.pending, (state) => {
        state.isLoading = true;
        state.record = [];
      })
      .addCase(get_roles.fulfilled, (state, action) => {
        state.isLoading = false;
        state.record = action.payload?.data;
      })
      .addCase(get_roles.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.record = [];
      })
      .addCase(create_role.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(create_role.fulfilled, (state, action) => {
        state.isLoading = false;
        state.record.push(action.payload.data);
      })
      .addCase(create_role.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_role.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_role.fulfilled, (state, action) => {
        state.isLoading = false;
        const result = state.record.findIndex(
          ({ _id }) => _id === action?.payload?.data?._id
        );
        state.record[result] = {
          ...state.record[result],
          ...action.payload.data,
        };
      })
      .addCase(update_role.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { reset } = rolesSlice.actions;
export default rolesSlice.reducer;
