import React from "react";
import { MdCancel } from "react-icons/md";
import PhoneInput from "react-phone-number-input";
const TagsInput = (props) => {
  const {
    tags,
    setTags,
    placeholder,
    className,
    disable,
    readOnly,
    phoneInput,
    label,
    name,
    isFloat,
    tagBg,
  } = props;
  const [phoneNumber, setPhoneNumber] = React.useState();
  const removeTags = (selectedTag) => {
    setTags([...tags?.filter((tag) => tag !== selectedTag)]);
  };
  const addTags = (event) => {
    event.preventDefault();
    if (event.target.value !== "" && !phoneInput) {
      setTags([...tags, event.target.value]);
      event.target.value = "";
    }
    if (event.target.value !== "" && phoneInput) {
      setTags([...tags, `+1${event.target.value}`]);
      event.target.value = "";
    }
  };
  const addNumber = (event) => {
    event.preventDefault();
    if (phoneNumber && phoneNumber !== "" && phoneInput) {
      setTags([phoneNumber, ...tags]);
      event.target.value = "";
      setPhoneNumber("");
    }
  };
  function handlePaste(event) {
    event.preventDefault();
    const text = event.clipboardData.getData("text/plain");
    if (text.trim().indexOf(" ") !== -1) {
      event.preventDefault();
    }
    const demo = text.replaceAll("\n", ",");
    const cities = demo.split(",")?.map((city) => city.trim());
    const uniqueCities = [...new Set(cities)];
    setTags([...tags, ...uniqueCities]);
  }
  return (
    <div
      className={`form-control inline-flex flex-wrap !flex items-center relative min-h-[40px] h-auto !border-[#a9a9a9] ${className}`}
    >
      {label && (
        <label
          htmlFor={name}
          className={[
            `small`,
            isFloat
              ? "text-[rgba(0,_0,_0,_0.6)] absolute -left-[5px] top-0 font-normal text-base truncate max-w-[calc(133%-24px)] translate-x- -translate-y-[13px] scale-75 px-1"
              : "",
          ].join(" ")}
          style={{
            background: "linear-gradient(0deg, white 50%, transparent 50%)",
          }}
        >
          {label}
        </label>
      )}
      {tags?.map((tag, index) => (
        <div
          className={`badge me-1 pe-1 badge justify-between items-center !mr-2 my-0.5 h-[26.39px] ${
            tagBg ? tagBg : "bg-secondary text-white"
          }`}
          key={index}
        >
          {tag}
          <button
            data-testid="tag-clean-element"
            aria-label="remove path fragment"
            tabindex="-1"
            className="border-0 bg-transparent ps-auto pe-0"
          >
            <MdCancel
              size={20}
              className="ml-0.5 cursor-pointer text-whit"
              onClick={() => removeTags(tag)}
            />
          </button>
        </div>
      ))}

      {phoneInput ? (
        <PhoneInput
          defaultCountry="US"
          initialValueFormat="national"
          onChange={(e) => setPhoneNumber(e)}
          onBlur={(event) => addNumber(event)}
          onKeyUp={(event) =>
            event.key === "Enter" || event.key === "," ? addNumber(event) : null
          }
          value={phoneNumber}
          placeholder="Add Phone Number"
        />
      ) : (
        <input
          type="text"
          onKeyUp={(event) =>
            event.key === "Enter" || event.key === "," ? addTags(event) : null
          }
          placeholder={placeholder ? placeholder : "Add new data"}
          onPaste={handlePaste}
          className={`w-auto flex-fill input-tags ${
            tags?.length > 0 ? "!border-l border-[#D9D9D9] px-2 h-full" : ""
          } `}
          onBlur={(event) => addTags(event)}
          readOnly={readOnly}
          disabled={disable}
        />
      )}
    </div>
  );
};

export default TagsInput;
