import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  callByLink,
  callMonitor,
  getLeadDid,
  getMissedCalls,
  getRealTime,
  getRecordings,
} from "../services/dialerServices";

const initialState = {
  iframeUseRef: null,
  phone_number: "",
  status: "",
  ext: "",
  timer: "",
  campaign: "",
  classColor: "",
  isStopAfterDispo: false,
  isTech: "initial values",
  isLoading: false,
  recordings: [],
  realtime: null,
  leadDid: null,
  callType: "",
  dialer_lead_id: "",
  vicidial_id: "",
  did_pattern: "",
  did_description: "",
  conversation_id: "",
  callTypeD: "",
  phone_numberD: "",
  isJobDone: false,
  missedCalls: [],
  missedCallsCount: 0,
  did_patternD: "",
  did_descriptionD: "",
  inGroupD: "",
};
const getErrorMessage = (error) => {
  return (
    error?.response?.data?.error ||
    (error?.response?.data?.debugInfo && [error?.response?.data?.debugInfo]) ||
    error?.response?.data?.message ||
    error?.response?.data ||
    error.toString()
  );
};
export const call_by_link = createAsyncThunk(
  "call_on",
  async (phone_num, thunkAPI) => {
    try {
      return await callByLink(phone_num);
    } catch (error) {
      console.error("🚀 ~ file: dialerSlice.js:41 ~ error:", error);
      const message = error?.response?.data?.message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const get_recordings = createAsyncThunk(
  "get_recordings",
  async (job_number, thunkAPI) => {
    try {
      return await getRecordings(job_number);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const get_realtime_data = createAsyncThunk(
  "get_realtime_data",
  async (thunkAPI) => {
    try {
      return await getRealTime();
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const get_lead_did = createAsyncThunk(
  "get_lead_did",
  async (data, thunkAPI) => {
    try {
      return await getLeadDid(data);
    } catch (error) {
      console.error("🚀 ~ file: dialerSlice.js:71 ~ error:", error);
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const call_monitor = createAsyncThunk(
  "call_monitor",
  async (data, thunkAPI) => {
    try {
      return await callMonitor(data);
    } catch (error) {
      console.error("🚀 ~ file: dialerSlice.js:71 ~ error:", error);
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const get_missedcall_count = createAsyncThunk(
  "get_missedcall_count",
  async (data, thunkAPI) => {
    try {
      return await getMissedCalls(data);
    } catch (error) {
      console.error("🚀 ~ file: dialerSlice.js:71 ~ error:", error);
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const get_missedcalls = createAsyncThunk(
  "get_missedcalls",
  async (data, thunkAPI) => {
    try {
      return await getMissedCalls(data);
    } catch (error) {
      console.error("🚀 ~ file: dialerSlice.js:71 ~ error:", error);
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
const dialerSlice = createSlice({
  name: "dialer",
  initialState,
  reducers: {
    setRef: (state, action) => {
      state.iframeUseRef = action?.payload;
    },
    sendIframeMsg: (state, action) => {
      if (!state.iframeUseRef?.current) return;
      console.log("--------- sendMessage ------");
      state.iframeUseRef?.current?.contentWindow?.postMessage(
        action.payload,
        "*"
      );
    },
    setDialerPhone: (state, action) => {
      state.dialer_lead_id = action?.payload?.dialer_lead_id;
      state.vicidial_id = action?.payload?.vicidial_id;
      state.callType = action?.payload?.callType;
      state.phone_number = action?.payload?.phone;
      state.did_pattern = action?.payload?.did_pattern;
      state.did_description = action?.payload?.did_description;
      // state.conversation_id=action?.payload?.conversation_id
    },
    isJobComplete: (state, action) => {
      state.isJobDone = action.payload;
    },
    setDialerPhoneD: (state, action) => {
      state.phone_numberD = action?.payload?.phone;
      state.callTypeD = action?.payload?.callType;
      state.did_patternD = action?.payload?.did_pattern;
      state.did_descriptionD = action?.payload?.did_description;
    },
    set_conversationId: (state, action) => {
      state.conversation_id = action?.payload?.conversation_id;
    },
    setStatus: (state, action) => {
      state.status = action?.payload;
    },
    setExt: (state, action) => {
      state.ext = action?.payload;
    },
    setTimer: (state, action) => {
      state.timer = action?.payload;
    },
    setInGroup: (state, action) => {
      state.inGroupD = action?.payload;
    },
    setCampaign: (state, action) => {
      state.campaign = action?.payload;
    },
    setColor: (state, action) => {
      state.classColor = action?.payload;
    },
    setDispoSelectStop: (state, action) => {
      state.isStopAfterDispo = action?.payload;
    },
    resetRecordings: (state, action) => {
      state.recordings = [];
    },
    resetLeadDid: (state, action) => {
      state.leadDid = null;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(call_by_link.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(call_by_link.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload?.data?.error) {
          toast.error(
            action.payload
              ? action.payload
              : action.payload?.data?.message
              ? action.payload?.data?.message
              : "Unknown Error"
          );
        }
      })
      .addCase(call_by_link.rejected, (state, action) => {
        state.isLoading = false;
        toast.error(
          action.payload
            ? action.payload
            : action.payload?.data?.message
            ? action.payload?.data?.message
            : "Unknown Error"
        );
      })
      .addCase(get_recordings.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_recordings.fulfilled, (state, action) => {
        state.isLoading = false;
        state.recordings = action.payload?.data;
      })
      .addCase(get_recordings.rejected, (state, action) => {
        state.isLoading = false;
        toast.error(action.payload ? action.payload : "Cloudn't get the data");
      })
      .addCase(get_realtime_data.pending, (state) => {})
      .addCase(get_realtime_data.fulfilled, (state, action) => {
        state.realtime = action.payload?.data;
      })
      .addCase(call_monitor.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(call_monitor.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload?.data.includes("SUCCESS")) {
          toast.success("Action called successfully");
        } else {
          toast.error("Getting an error");
        }
      })
      .addCase(call_monitor.rejected, (state, action) => {
        state.isLoading = false;
        toast.error("Getting an error");
        // toast.error(action.payload?action.payload:"Cloudn't get the data")
      })

      .addCase(get_lead_did.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_lead_did.fulfilled, (state, action) => {
        state.isLoading = false;
        state.leadDid = action.payload?.data;
      })
      .addCase(get_lead_did.rejected, (state, action) => {
        state.isLoading = false;
        // toast.error(action.payload?action.payload:"Cloudn't get the data")
      })
      .addCase(get_missedcall_count.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_missedcall_count.fulfilled, (state, action) => {
        state.isLoading = false;
        state.missedCallsCount = {
          ...action.payload?.data,
          records: [...action.payload?.data?.records]?.reverse(),
        };
      })
      .addCase(get_missedcall_count.rejected, (state, action) => {
        state.isLoading = false;
        state.missedCallsCount = 0;
      })
      .addCase(get_missedcalls.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_missedcalls.fulfilled, (state, action) => {
        state.isLoading = false;
        state.missedCalls = action.payload?.data;
      })
      .addCase(get_missedcalls.rejected, (state, action) => {
        state.isLoading = false;
        state.missedCalls = [];
        toast.error("Got an error to get the missed calls acount");
        // toast.error(action.payload?action.payload:"Cloudn't get the data")
      });
  },
});

const dialerReducer = dialerSlice.reducer;
export const {
  sendIframeMsg,
  setRef,
  setDialerPhone,
  setStatus,
  setExt,
  setTimer,
  setColor,
  setDispoSelectStop,
  resetRecordings,
  resetLeadDid,
  set_conversationId,
  setDialerPhoneD,
  isJobComplete,
  setCampaign,
  setInGroup,
} = dialerSlice.actions;
export default dialerReducer;
