import api from "./api";

export const getWorkflowStatus = async () => {
  return await api.get("/api/workflowstatus");
};
export const createWorkflowStatus = async (data) => {
  return await api.post("/api/workflowstatus", data);
};

export const updateWorkflowStatus = async (data) => {
  return await api.put(`/api/workflowstatus/${data?.id}`, data);
};

export const deleteWorkflowStatus = async (id) => {
  return await api.delete(`/api/workflowstatus/${id}`);
};
