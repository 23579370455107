import React from "react";
import ReactDOM from "react-dom";

const Backdrop = (props) =>
  ReactDOM.createPortal(
    <div
      className="w-full h-screen z-[100] fixed top-0 left-[0] opacity-100 bg-black/[0.75]"
      onClick={props.onClick}
    />,
    document.getElementById("backdrop-root")
  );

export default Backdrop;
