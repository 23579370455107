import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { getSettings, updateSettings } from "../services/generalSettingService";

const initialState = {
  isError: false,
  isLoading: false,
  settings: [],
  message: "",
};

const getErrorMessage = (error) => {
  return (
    error?.response?.data?.error || 
    (error?.response?.data?.debugInfo&&[error?.response?.data?.debugInfo]) ||
    error?.response?.data?.message ||
    error?.response?.data ||
    error.toString()
  );
};
// Get Users Roles
export const get_general_setting = createAsyncThunk("get_general_setting", async (thunkAPI) => {
  try {
    return await getSettings();
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Update Roles
export const update_general_setting = createAsyncThunk(
  "update_general_setting",
  async (data, thunkAPI) => {
    try {
      return await updateSettings(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const generalSettingSlice = createSlice({
  name: "generalSetting",
  initialState,
  reducers: {
    generalSettingReset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.settings = [];
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_general_setting.pending, (state) => {
        state.isLoading = true;
        state.settings = [];
      })
      .addCase(get_general_setting.fulfilled, (state, action) => {
        state.isLoading = false;
        state.settings = action.payload?.data;
      })
      .addCase(get_general_setting.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.settings = [];
        toast.error(action.payload?action.payload:"Unknown Error")
      })
      .addCase(update_general_setting.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_general_setting.fulfilled, (state, action) => {
        state.isLoading = false;
        const result = state.settings.findIndex(
          ({ _id }) => _id === action?.payload?.data?._id
        );
        state.settings[result] = {
          ...state.settings[result],
          ...action.payload.data,
        };
      })
      .addCase(update_general_setting.rejected, (state, action) => {
        state.isLoading = false;
        toast.error(action.payload?action.payload:"Unknown Error")
      });
  },
});

export const { generalSettingReset } = generalSettingSlice.actions;
export default generalSettingSlice.reducer;
