import React, { useRef } from "react";
import DotsLoader from "../../atoms/DotsLoader";
import Draggable from "react-draggable";
import { setIframeRef } from "../../../util/iFrame";
export function Dialer({
  modalTitle,
  isDialer,
  setIsDialer,
  setIsDroping,
  setIsDroped,
  isDroped,
  setIsDropingOut,
}) {
  let user = localStorage.getItem("user");
  user = user ? JSON.parse(user) : null;
  const [isLoading, setIsLoading] = React.useState(true);
  const iFrameRef = useRef(null);
  React.useEffect(() => {
    setIframeRef(iFrameRef);
    // eslint-disable-next-line
  }, []);
  const [position, setPosition] = React.useState({ x: 0, y: 0 });

  return (
    <>
      <Draggable
        position={position}
        axis="x"
        onDrag={(e, data) => {
          if (data.x < -5 && !isDroped) {
            setIsDroping(true);
          } else if (data.x > -5) {
            setIsDroping(false);
            setPosition({ x: data.x, y: 0 });
          }
          if (data.x > -180 && isDroped) {
            setIsDropingOut(true);
          } else if (data.x < -5) {
            setIsDropingOut(false);
          }
        }}
        onStop={(e, data) => {
          if (data.x < -5) {
            setPosition({ x: -223, y: 40 });
            setIsDroped(true);
            setIsDroping(false);
          } else {
            setPosition({ x: data.x, y: 0 });
            setIsDroped(false);
            setIsDroping(false);
          }
          if (data.x > -180 && isDroped) {
            setPosition({ x: 0, y: 0 });
            setIsDroped(false);
            setIsDropingOut(false);
          }
        }}
        cancel="button, input, i,iframe"
      >
        <div
          id="dialer"
          className={`${position.x < -5 ? "transition-all" : ""}${
            isDroped ? "!fixed" : ""
          } justify-between items-center w-full relative cursor-move z-[1111] ${isDialer}`}
        >
          <div
            className={`fixed overflow-y-auto bottom-4 left-56
             bg-white rounded-t-md z-10 shadow-3xl`}
          >
            {isLoading && <DotsLoader />}
            <header className="border-b-2 border-[#3b0062] flex justify-between items-center">
              <h1 className="text-base text-[#3b0062] mx-2.5 font-medium">
                {modalTitle}
              </h1>
              <i
                className="fa fa-minus text-2xl text-[#3b0062] mx-2.5 cursor-pointer"
                onClick={() => setIsDialer("hidden")}
              ></i>
            </header>
            <div className="p-0.5">
              <iframe
                title="SOFTPHONE"
                ref={iFrameRef}
                allowFullScreen
                frameBorder="0"
                height="300px"
                src={
                  user?.dialer_login_url
                    ? user?.dialer_login_url
                    : ""
                }
                width="210px"
                scrolling="no"
                allow="microphone ; speakers ;"
                onLoad={() => {
                  setIsLoading(false);
                }}
              />
            </div>
          </div>
        </div>
      </Draggable>
    </>
  );
}

export default Dialer;
