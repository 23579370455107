import React from "react";
import { FaAngleDown } from "react-icons/fa";
import { useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import SubDropdown from "./SubDropdown";
function Dropdown(props) {
  const { options, show_navlabel, to, className, subMenuClass } = props;
  const [isOpen, setIsOpen] = React.useState(false);
  const { status, isJobDone } = useSelector((state) => state.dialer);
  let user = localStorage.getItem("user");
  user = user ? JSON.parse(user) : null;
  const isAllow = (item) => {
    if (user?.permissions?.length > 0 ) {
      if (user?.permissions?.some((obj) => obj === item?.label)) {
        return true;
      }
      return false;
    }
  };
  const location = useLocation();
  const [clickedOn, setClickedOn] = React.useState("");
  React.useEffect(() => {
    let route = location.pathname.split("/")[2];

    setClickedOn(`${route}`);
  }, [location.pathname]);
  const disbale =
    status && status !== "PAUSE" && status !== "WAIT" && !isJobDone;
  return (
    <div className={`w-full transition-all ${className}`}>
      <div className="w-full transition-all">
        {options.map((item, key) => {
          let Icon = item?.Icon;
          return isAllow(item) ? (
            <div className="w-full transition-all" key={key}>
              {item?.children ? (
                <>
                  <div
                    className={`transition-all page_link group hover:text-[#828282] hover:no-underline text-[#828282] transition flex items-center justify-between py-[3px] pl-8 !pr-5 w-full outline-none border-0 cursor-pointer transition-all ${subMenuClass}`}
                    onClick={() => {
                      setClickedOn(clickedOn === item.to ? "" : item.to);
                      setIsOpen(!isOpen);
                    }}
                  >
                    <div className="flex items-center">
                      <Icon className="icons group-hover:text-primary-100" />
                      {show_navlabel && (
                        <span className="!ml-1">{item?.label}</span>
                      )}
                    </div>
                    <FaAngleDown />
                  </div>
                  {clickedOn === item.to && !props?.isDroping && !disbale && (
                    <SubDropdown
                      show_navlabel={show_navlabel}
                      label={item?.label}
                      to={`${to}/${item.to}`}
                      Icon={Icon}
                      options={item?.children}
                      key={key}
                    />
                  )}
                </>
              ) : (
                <NavLink
                  title={item.label}
                  to={`${to}/${item.to}`}
                  className="hover:no-underline no-underline hover:text-[#828282] page_link group text-[#828282] flex items-center py-[3px] pl-8 w-full outline-none border-0 cursor-pointer transition-all"
                >
                  <Icon className="group-hover:text-primary-100 icons" />
                  {show_navlabel && (
                    <span className="ml-1">
                      {item.label}
                      {user?.pending_domains_phone_count &&
                      item?.label === "Pending Phones" ? (
                        <span className="animate-blink bg-red-700 px-1.5 text-center text-white rounded-lg ml-0.5">
                          {`${user?.pending_domains_phone_count || 0}`}
                        </span>
                      ) : null}
                    </span>
                  )}
                </NavLink>
              )}
            </div>
          ) : null;
        })}
      </div>
    </div>
  );
}

export default Dropdown;
