import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  createDids,
  deleteDids,
  getAllCCCustomers,
  getCCCard,
  getCCProviders,
  getDids,
  updateDids,
} from "../services/didService";
const initialState = {
  isError: false,
  isLoading: false,
  record: [],
  ccAllCustomers: [],
  ccCards: [],
  ccProviders: [],
  message: "",
};
const getErrorMessage = (error) => {
  return (
    error?.response?.data?.error ||
    (error?.response?.data?.debugInfo && [error?.response?.data?.debugInfo]) ||
    error?.response?.data?.message ||
    error?.response?.data ||
    error.toString()
  );
};
// Get DIDs Data
export const get_dids = createAsyncThunk("get_dids", async (data, thunkAPI) => {
  try {
    return await getDids(data);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Create New DIDs
export const create_dids = createAsyncThunk(
  "create_dids",
  async (data, thunkAPI) => {
    try {
      return await createDids(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update DIDs
export const update_dids = createAsyncThunk(
  "update_dids",
  async (data, thunkAPI) => {
    try {
      return await updateDids(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Delete DIDs
export const delete_dids = createAsyncThunk(
  "delete_dids",
  async (id, thunkAPI) => {
    try {
      return await deleteDids(id);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get DIDs Data
export const get_cc_all_customers = createAsyncThunk(
  "get_cc_all_customers",
  async (data, thunkAPI) => {
    try {
      return await getAllCCCustomers(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get DIDs Data
export const get_cc_cards = createAsyncThunk(
  "get_cc_cards",
  async (data, thunkAPI) => {
    try {
      return await getCCCard(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get DIDs Data
export const get_cc_providers = createAsyncThunk(
  "get_cc_providers",
  async (data, thunkAPI) => {
    try {
      return await getCCProviders(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const didSlice = createSlice({
  name: "inboundGroup",
  initialState,
  reducers: {
    didReset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.record = [];
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_dids.pending, (state) => {
        state.isLoading = true;
        state.record = [];
      })
      .addCase(get_dids.fulfilled, (state, action) => {
        state.isLoading = false;
        state.record = action.payload?.data;
      })
      .addCase(get_dids.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.record = [];
        toast.error(action.payload ? action.payload : "Unknown Error");
      })
      .addCase(create_dids.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(create_dids.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(create_dids.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_dids.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_dids.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_dids.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(delete_dids.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(delete_dids.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(delete_dids.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(get_cc_all_customers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_cc_all_customers.fulfilled, (state, action) => {
        state.ccAllCustomers = action.payload.data;
        state.isLoading = false;
      })
      .addCase(get_cc_all_customers.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(get_cc_cards.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_cc_cards.fulfilled, (state, action) => {
        state.ccCards = action.payload.data;
        state.isLoading = false;
      })
      .addCase(get_cc_cards.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(get_cc_providers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_cc_providers.fulfilled, (state, action) => {
        state.ccProviders = action.payload.data;
        state.isLoading = false;
      })
      .addCase(get_cc_providers.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export const { didReset } = didSlice.actions;
export default didSlice.reducer;
