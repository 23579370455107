import React from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  Backdrop,
  Button,
  DotsLoader,
  FormInput,
  FormSelectInput,
} from "../../components";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  create_template,
  update_template,
} from "../../features/emailTemplateSlice";
import { toast } from "react-toastify";
import errorMessage from "../../util/errorMessage";
import TagsInput from "../../components/atoms/CustomTagsInput";
import HtmlEditor from "./HtmlEditor";
const AddUpdateForm = ({ editingRecord, onCancelForm, modalTitle }) => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.emailTemplate);
  const [errors, setErrors] = React.useState([]);
  let initialValues = {
    subject: "",
    name: "",
    slug: "",
    structure: "",
    is_default: false,
    from_email: "",
    bcc_email: [],
    cc_email: [],
    industries:"",
    to_email: [],
    notification_allow: true,
  };

  if (editingRecord) {
    const {
      _id,
      subject,
      name,
      slug,
      structure,
      is_default,
      from_email,
      bcc_email,
      cc_email,
      industries,
      to_email,
      notification_allow,
    } = editingRecord;
    initialValues = {
      id: _id,
      subject,
      name,
      slug,
      structure,
      is_default,
      from_email,
      bcc_email,
      cc_email,
      industries: industries?.map(({ _id }) => _id)||"",
      to_email,
      notification_allow,
    };
  }
  const toolbarOptions = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      [{ font: [] }],
      [{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ align: [] }],
      ["link", "image"],
      ["clean"], // Remove formatting button
      ["blockquote", "code-block"], // Blockquote and code block
    ],
  };
  const formik = useFormik({
    initialValues,
    validateOnBlur: true,
    onSubmit: async (values) => {
      if (!editingRecord) {
        try {
          const res = await dispatch(create_template({ ...values }));
          if (res?.payload?.status === 200) {
            toast.success("Email Template created");
            onCancelForm();
          } else {
            errorMessage({
              payload: res.payload,
              setErrors: setErrors,
              action: "Email Template",
              msg: "created",
            });
          }
        } catch (error) {
          errorMessage({
            payload: error,
            setErrors: setErrors,
            action: "Email Template",
            msg: "created",
          });
        }
      } else {
        try {
          const res = await dispatch(update_template({ ...values }));
          if (res?.payload?.status === 200) {
            toast.success("Email Template updated");
            onCancelForm();
          } else {
            errorMessage({
              payload: res.payload,
              setErrors: setErrors,
              action: "Email Template",
              msg: "updated",
            });
          }
        } catch (error) {
          errorMessage({
            payload: error,
            setErrors: setErrors,
            action: "Email Template",
            msg: "created",
          });
        }
      }
    },
  });
  const handleChange = React.useCallback((newValue) => {
    formik.setFieldValue("structure", newValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="min-h-[90vh] md:min-h-screen bg-white w-full z-[100] fixed top-0 left-0 overflow-y-auto">
      <div className="overflow-y-auto h-screen">
        {isLoading && <DotsLoader />}
        <div className="flex items-center justify-between w-full text-client-50 py-2.5 px-5 shadow-client">
          <h4>
            {!!editingRecord
              ? modalTitle.replaceAll("Add", "Edit")
              : modalTitle}
          </h4>
          <Button
            text="X"
            className="mr-2"
            onClick={onCancelForm}
            variant="btn_danger"
          />
        </div>
        <Backdrop onClick={onCancelForm} />
        <div className="!p-5 grid md:grid-cols-[repeat(2,_1fr)] gap-5">
          <div className="!py-5 px-10 bg-white rounded-xl shadow-client">
            <div className="grid md:grid-cols-2 gap-x-5">
              <div className="!mb-3">
                <FormInput
                  errors={errors}
                  name="subject"
                  label="Subject"
                  formik={formik}
                  required={true}
                />
              </div>
              <div className="!mb-3">
                <FormInput
                  errors={errors}
                  name="name"
                  label="Name"
                  formik={formik}
                  required={true}
                />
              </div>
              <div className="!mb-3">
                <FormInput
                  errors={errors}
                  name="slug"
                  label="Slug"
                  formik={formik}
                  required={true}
                />
              </div>
              <div className="!mb-3">
                <FormInput
                  errors={errors}
                  name="from_email"
                  label="From Email"
                  formik={formik}
                  required={true}
                />
              </div>
              <div className="!mb-3">
                <FormSelectInput
                  errors={errors}
                  name="is_default"
                  label="Default"
                  formik={formik}
                  options={[
                    { value: false, label: "No" },
                    { value: true, label: "Yes" },
                  ]}
                  valueProp="value"
                  labelProp="label"
                />
              </div>
              <div className="!mb-3">
                <FormSelectInput
                  errors={errors}
                  name="notification_allow"
                  label="Allow Notification"
                  formik={formik}
                  options={[
                    { value: false, label: "No" },
                    { value: true, label: "Yes" },
                  ]}
                  valueProp="value"
                  labelProp="label"
                />
              </div>
              <div className="col-span-2 !my-3">
                <TagsInput
                  tags={formik.values.bcc_email}
                  setTags={(e) => formik.setFieldValue("bcc_email", e)}
                  label="BCC Emails"
                  placeholder="Add New Email"
                  isFloat
                />
              </div>
              <div className="col-span-2 !mb-3">
                <TagsInput
                  tags={formik.values.cc_email}
                  setTags={(e) => formik.setFieldValue("cc_email", e)}
                  label="CC Emails"
                  placeholder="Add New Email"
                  isFloat
                />
              </div>
              <div className="col-span-2 !mb-3">
                <TagsInput
                  tags={formik.values.to_email}
                  setTags={(e) => formik.setFieldValue("to_email", e)}
                  label="To Emails"
                  placeholder="Add New Email"
                  isFloat
                />
              </div>
            </div>
            <div>
              <h4>Placeholders</h4>
              <div className="text-xs h-32 overflow-y-auto bg-white border border-[rgb(215,_215,_215)] p-2.5 rounded">
                _AGENT_EMAIL_ = Agent Email
                <br />
                _AGENT_FAX_ = Agent Fax
                <br />
                _AGENT_NAME_ = Agent Name
                <br />
                _AGENT_PHONE_ = Agent Phone
                <br />
                _AGENT_EXT_ = Agent Extension
                <br />
                __APPLICATION_LINK__ = Application Link
                <br />
                _COMPANY_ADDRESS_ = Company Address
                <br />
                _COMPANY_NAME_ = Company Name
                <br />
                _COMPANY_URL_ = Company Website URL
                <br />
                __CUSTOMER_DETAIL_URL__ = Customer Detail URL
                <br />
                __CUSTOMER_DETAIL_URL_1__ = Apply Now
                <br />
                __CUSTOMER_DETAIL_URL_2__ = Learn More
                <br />
                __DATE__ = Current Date
                <br />
                __EMAIL__ = Customer Email Address
                <br />
                __FORM_LINK__ = Authorize Form Link
                <br />
                __LOGO__ = Logo Image
                <br />
                __NAME__ = Customer Name
                <br />
                __USER_NAME__ = User Name
                <br />
                _OFFICE_NUMBER_ = Office Contact Number
                <br />
                __PHONE__ = Customer Phone Number
                <br />
              </div>
              <br />
              <div>
                <Button text="Cancel" className="mr-2" variant="btn_danger" />
                <Button
                  text="Add files"
                  className="mr-2"
                  variant="btn_submit"
                />
              </div>
            </div>
          </div>
          <div className="!py-5 px-10 bg-white rounded-xl shadow-client mb-12">
            <HtmlEditor
              inputValue={formik.values.structure}
              onChange={handleChange}
              options={toolbarOptions}
            />
            {/*  <SimpleMDE
              // ref={simpleMdeRef}
              value={formik.values.structure}
              onChange={handleChange}
              options={toolbarOptions}
            /> */}
          </div>
        </div>
        <div className="w-full absolute flex justify-center bottom-0 !p-5 bg-white border-t">
          <Button
            text="Cancel"
            className="mr-2"
            onClick={onCancelForm}
            variant="btn_cancel"
          />
          <Button
            text="Submit"
            className="mr-2"
            onClick={formik.handleSubmit}
            variant="btn_submit"
          />
        </div>
      </div>
    </div>
  );
};

export default AddUpdateForm;
