import api from "./api";

export const login = async (user, header) => {
  return await api.post(`/api/auth`, user, { headers: header });
};

export const vendorLogin = async (user, header) => {
  return await api.post(`/vendors/login`, user, { headers: header });
};

export const logout = async () => {
  return await api.get(`/api/logout`, {
    headers: {
      refreshToken: localStorage.getItem("refreshToken"),
    },
  });
};

export const sendResetEmail = async (email) => {
  return await api.post(`/api/auth/recover`, email);
};

export const checkToken = async (token) => {
  return await api.get(`api/auth/reset/${token}`, token);
};

export const confirmPassword = async ({ data, token }) => {
  return await api.post(`api/auth/reset/${token}`, data);
};
