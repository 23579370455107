import api from "./api";

export const getDistributionCategory = async () => {
  return await api.get("/api/distribution_category");
};
export const createDistributionCategory = async (data) => {
  return await api.post("/api/distribution_category", data);
};

export const updateDistributionCategory = async (data) => {
  return await api.put(`/api/distribution_category/${data?.id}`, data);
};

export const deleteDistributionCategory = async (id) => {
  return await api.delete(`/api/distribution_category/${id}`);
};
