import api from "./api";

export const getCallCenters = async () => {
  return await api.get(`/api/call_centers`);
};
export const createCallCenters = async (data) => {
  return await api.post("/api/call_centers", data);
};
export const updateCallCenters = async (data) => {
  const payload={
 ...data
  }
  delete payload._id
  return await api.put(`/api/call_centers/${data._id}`, payload);
};
// export const deleteInboundGroup = async (id) => {
//   return await api.delete("/api/cc/inbounds", { data: { id } });
// };
