import api from "./api";

export const getLeadSourceGroup = async (data) => {
  return await api.post(`/api/lead_source/group`,data);
};
export const createLeadSourceGroup = async (data) => {
  return await api.post("/api/lead_source/group/add_lead_group", data);
};
export const updateLeadSourceGroup = async (data) => {
  return await api.put(`/api/lead_source/group/${data?._id}`, data);
};
export const deleteLeadSourceGroup = async (id) => {
  return await api.delete(`/api/lead_source/group/${id}`);
};
