import api from "./api";

export const getRoles = async () => {
  return await api.get("/api/roles");
};
export const createRole = async (data) => {
  return await api.post("/api/roles", data);
};
export const updateRole = async (data) => {
  return await api.put("/api/roles", data);
};