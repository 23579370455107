import { TextField } from "@mui/material";
const FormInput = ({
  name,
  label,
  formik,
  type,
  disabled = false,
  onClick,
  placeholder,
  value,
  readOnly,
  required,
  errors,
  className,
  onChange,
  onKeyUp,
  onFocus,
  rootClass="",
}) => {
  return (
    <div className={`flex flex-col ${rootClass}`}>
      <TextField
        id="standard-password-input"
        label={label ? label : placeholder}
        variant="outlined"
        sx={{
          "& label.Mui-focused": {
            color: "#000",
          },

          "& .MuiOutlinedInput-root": {
            backgroundColor: "#fff",

            "& fieldset": {
              borderColor: "#a9a9a9",
            },
            "&:hover fieldset": {
              borderColor: "#a9a9a9",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#a9a9a9",
            },
            "&.Mui-disabled": {
              backgroundColor: "#f8f8f8",
              cursor: "not-allowed",
            },
          },

          "&  .MuiFormHelperText-root.Mui-error": {
            backgroundColor: "transparent ",
            margin: 0,
            paddingLeft: 2,
          },
          mt: 0,
        }}
        margin="dense"
        size="small"
        name={name}
        type={type}
        value={
          formik?.values[name] || formik?.values[name] === 0
            ? formik?.values[name]
            : value || value === 0
            ? value
            : ""
        }
        onChange={onChange ? onChange : formik?.handleChange}
        onBlur={formik?.handleBlur}
        disabled={disabled}
        onClick={onClick}
        required={required}
        error={formik?.errors[name] && formik?.touched[name] ? true : false}
        className={className}
        InputProps={{
          readOnly: readOnly,
        }}
        onKeyUp={onKeyUp}
        onFocus={onFocus}
        onKeyDown={(event) => {
          event.stopPropagation(); // must remove it
        }}
      />
      {formik?.errors[name] && formik?.touched[name] && (
        <small
          style={{
            fontSize: 12,
            marginTop: -5,
            paddingLeft: 16,
            color: "#D32F2F",
          }}
        >
          {formik.errors[name]}
        </small>
      )}
      {errors !== undefined &&
        errors?.length > 0 &&
        errors?.map((error, index) => {
          return (
            <>
              {error[name] && (
                <small
                  style={{
                    fontSize: 12,
                    marginTop: -5,
                    paddingLeft: 16,
                    color: "#D32F2F",
                  }}
                  key={index}
                >
                  {error[name]
                    .replaceAll(`"${name}"`, label)
                    .replaceAll(`${name}`, label)}
                </small>
              )}
            </>
          );
        })}
      {errors !== undefined && typeof errors === "object" && (
        <>
          {errors[name] && (
            <small
              style={{
                fontSize: 12,
                marginTop: 0,
                paddingLeft: 16,
                color: "#D32F2F",
                background: "transparent",
              }}
            >
              {errors[name][0]}
            </small>
          )}
        </>
      )}
    </div>
  );
};

export default FormInput;
