import api from "./api";

export const getSettings = async () => {
  return await api.get("/api/settings");
};
export const updateSettings = async (data) => {
  return await api.put("/api/settings", data);
};
export const getSettingEvents = async () => {
  return await api.get("/api/settings/app_event");
};
export const addSettingEvents = async (data) => {
  return await api.post(`/api/settings/app_event`, data);
};
export const updateSettingEvents = async (data) => {
  return await api.put(`/api/settings/app_event/${data._id}`, data);
};
export const sync_twilio_dids = async () => {
  return await api.get(`/api/settings/sync_twilio_dids`);
};
