// iframeUtils.js
let iframeRef = null;

export const setIframeRef = (ref) => {
  iframeRef = ref;
};

export const sendIframeMsg = (payload) => {
  if (!iframeRef?.current) return;
  console.log("--------- sendMessage ------",payload);
  iframeRef.current.contentWindow.postMessage(payload, "*");
};