import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createDistributionProgram,
  deleteDistributionProgram,
  getDistributionProgram,
  updateDistributionProgram,
  getUserProgramLead,
  createUserProgramLeadCount,
  createUserProgramLeadCountBulk,
  updateUserProgramLeadCount,
  getProgramUserLead,
} from "../services/distributionProgramService";

const initialState = {
  isError: false,
  isLoading: false,
  record: [],
  useFilters: null,
  message: "",
  programFilters:null,
};
const getErrorMessage = (error) => {
  return (
    error?.response?.data?.error ||
    (error?.response?.data?.debugInfo && [error?.response?.data?.debugInfo]) ||
    error?.response?.data?.message ||
    error?.response?.data ||
    error.toString()
  );
};
// Get Distribution Program
export const get_distribution_program = createAsyncThunk(
  "get_distribution_program",
  async (data, thunkAPI) => {
    try {
      return await getDistributionProgram(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Create New Distribution Program
export const create_distribution_program = createAsyncThunk(
  "create_distribution_program",
  async (data, thunkAPI) => {
    try {
      return await createDistributionProgram(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update Distribution Program
export const update_distribution_program = createAsyncThunk(
  "update_distribution_program",
  async (data, thunkAPI) => {
    try {
      return await updateDistributionProgram(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Delete Distribution Program
export const delete_distribution_program = createAsyncThunk(
  "delete_distribution_program",
  async (id, thunkAPI) => {
    try {
      return await deleteDistributionProgram(id);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get Distribution Program Lead Count
export const get_user_program_lead = createAsyncThunk(
  "get_user_program_lead",
  async (data, thunkAPI) => {
    try {
      return await getUserProgramLead(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get User Program
export const get_program_user_lead = createAsyncThunk(
  "get_program_user_lead",
  async (data, thunkAPI) => {
    try {
      return await getProgramUserLead(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Create New Distribution filter
export const create_user_program_lead_count = createAsyncThunk(
  "create_user_program_lead_count",
  async (data, slug, thunkAPI) => {
    try {
      return await createUserProgramLeadCount(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//Create distribution program lead count
export const create_user_program_lead_count_bulk = createAsyncThunk(
  "create_user_program_lead_count_bulk",
  async (data, slug, thunkAPI) => {
    try {
      return await createUserProgramLeadCountBulk(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Update Distribution Program
export const update_user_program_lead_count = createAsyncThunk(
  "update_user_program_lead_count",
  async (data, thunkAPI) => {
    try {
      return await updateUserProgramLeadCount(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const distributionProgramSlice = createSlice({
  name: "distributionProgram",
  initialState,
  reducers: {
    distributionProgramReset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.record = [];
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_distribution_program.pending, (state) => {
        state.isLoading = true;
        // state.record = [];
      })
      .addCase(get_distribution_program.fulfilled, (state, action) => {
        state.isLoading = false;
        state.record = action.payload?.data;
      })
      .addCase(get_distribution_program.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.record = [];
      })
      .addCase(create_distribution_program.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(create_distribution_program.fulfilled, (state, action) => {
        state.isLoading = false;
        state.record.push(action.payload.data);
      })
      .addCase(create_distribution_program.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_distribution_program.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_distribution_program.fulfilled, (state, action) => {
        state.isLoading = false;
        const result = state.record.findIndex(
          ({ _id }) => _id === action?.payload?.data?._id
        );
        state.record[result] = {
          ...state.record[result],
          ...action.payload.data,
        };
      })
      .addCase(update_distribution_program.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(delete_distribution_program.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(delete_distribution_program.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(delete_distribution_program.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(get_user_program_lead.pending, (state) => {
        state.isLoading = true;
        // state.record = [];
      })
      .addCase(get_user_program_lead.fulfilled, (state, action) => {
        state.isLoading = false;
        state.useFilters = action.payload?.data;
      })
      .addCase(get_user_program_lead.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.useFilters = null;
      })
      .addCase(create_user_program_lead_count.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(create_user_program_lead_count.fulfilled, (state, action) => {
        state.isLoading = false;
        state.record.push(action.payload.data);
      })
      .addCase(create_user_program_lead_count.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(create_user_program_lead_count_bulk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        create_user_program_lead_count_bulk.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.record.push(action.payload.data);
        }
      )
      .addCase(
        create_user_program_lead_count_bulk.rejected,
        (state, action) => {
          state.isLoading = false;
        }
      )
      .addCase(update_user_program_lead_count.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_user_program_lead_count.fulfilled, (state, action) => {
        state.isLoading = false;
        const result = state.record.findIndex(
          ({ _id }) => _id === action?.payload?.data?._id
        );
        state.record[result] = {
          ...state.record[result],
          ...action.payload.data,
        };
      })
      .addCase(update_user_program_lead_count.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(get_program_user_lead.pending, (state) => {
        state.isLoading = true;
        // state.record = [];
      })
      .addCase(get_program_user_lead.fulfilled, (state, action) => {
        state.isLoading = false;
        state.programFilters = action.payload?.data;
      })
      .addCase(get_program_user_lead.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.programFilters = null;
      });
  },
});

export const { distributionProgramReset } = distributionProgramSlice.actions;
export default distributionProgramSlice.reducer;
