const FormTextArea = ({
  rows = 16,
  name,
  label,
  formik,
  type,
  errors,
  value,
  onChange,
  className = "",
}) => {
  return (
    <div className={`${className}`}>
      <textarea
        name={name}
        rows={rows}
        type={type}
        value={
          formik?.values[name] || formik?.values[name] === 0
            ? formik?.values[name]
            : value || value === 0
            ? value
            : ""
        }
        placeholder={label}
        className={[
          "placeholder:text-[rgba(0,_0,_0,_0.6)] placeholder:font-normal placeholder:text-base placeholder:font-[Roboto,_Helvetica,_Arial,_sans-serif]",
          "text-sm rounded px-2 border border-[#ced4da] w-full",
          formik && formik.errors[name] && formik.touched[name]
            ? "invalid"
            : "",
        ].join(" ")}
        onChange={onChange ? onChange : formik?.handleChange}
        onBlur={formik && formik.handleBlur}
      />
      {formik && formik.errors[name] && formik.touched[name] && (
        <small className="form-text text-muted">{formik.errors[name]}</small>
      )}
      {errors &&
        errors !== undefined &&
        errors?.length > 0 &&
        errors?.map((error, index) => {
          return (
            <>
              {error[name] && (
                <small
                  style={{
                    fontSize: 12,
                    marginTop: -5,
                    paddingLeft: 16,
                    color: "#D32F2F",
                  }}
                  key={index}
                >
                  {error[name]
                    .replaceAll(`"${name}"`, label)
                    .replaceAll(`${name}`, label)}
                </small>
              )}
            </>
          );
        })}
      {errors !== undefined && typeof errors === "object" && (
        <>
          {errors[name] && (
            <small
              style={{
                fontSize: 12,
                marginTop: 0,
                paddingLeft: 16,
                color: "#D32F2F",
                background: "transparent",
              }}
            >
              {errors[name][0]}
            </small>
          )}
        </>
      )}
    </div>
  );
};

export default FormTextArea;
