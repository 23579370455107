import api from "./api";

export const getInboundGroup = async () => {
  return await api.get(`/api/cc/inbounds`);
};
export const createInboundGroup = async (data) => {
  return await api.post("/api/cc/inbounds", data);
};
export const updateInboundGroup = async (data) => {
  return await api.put("/api/cc/inbounds", data);
};
export const deleteInboundGroup = async (id) => {
  return await api.delete("/api/cc/inbounds", { data: { id } });
};
export const getAgentPerformacne = async (data) => {
  return await api.post("/api/cc/reports/agent_performance_report", data);
};
export const getCallSummaryReport = async (data) => {
  return await api.post("/api/cc/reports/call_summary_report", data);
};

export const getAudioFiles = async () => {
  return await api.get("/api/cc/dids/options/call_audios");
};

export const getCallMenu = async () => {
  return await api.get("/api/cc/dids/options/call_menus");
};
export const getCallMenuById = async (id) => {
  return await api.get(`/api/cc/dids/options/call_menu/${id}`);
};
export const createCallMenu = async (data) => {
  return await api.post("/api/cc/dids/options/call_menu", data);
};
export const updateCallMenu = async (data) => {
  return await api.put(`/api/cc/dids/options/call_menu`, data);
};
export const createAudioFiles = async (data) => {
  return await api.post("/api/cc/dids/options/call_audio", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
export const getInboundGroupDrd = async () => {
  return await api.get(`/api/cc/inbounds/drd`);
};

export const getSpamNumbers = async (id) => {
  return await api.get(`/api/cc/dids/block_number`);
};
export const addSpamNumbers = async (data) => {
  return await api.post("/api/cc/dids/block_number", data);
};
export const deleteSpamNumbers = async (data) => {
  return await api.delete(`/api/cc/dids/block_number/${data}`, );
};