import axios from "axios";
import dayjs from "dayjs";
import jwtDecode from "jwt-decode";
import * as Sentry from "@sentry/react";

const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    "Content-Type": "application/json",
  },
});
let refreshTokenPromise;

api.interceptors.request.use(
  async (config) => {
    const { accessToken, refreshToken } = localStorage;
    let user = localStorage.getItem("user");
    user = user ? JSON.parse(user) : null;

    if (accessToken) {
      const jwt = jwtDecode(accessToken);

      const isExpired = dayjs.unix(jwt.exp).diff(dayjs()) < 3;

      if (!isExpired) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      } else {
        if (!refreshTokenPromise) {
          refreshTokenPromise = axios
            .get("/api/refresh", {
              baseURL: process.env.REACT_APP_BACKEND_URL,
              headers: { refreshToken },
            })
            .then((res) => {
              localStorage.setItem("accessToken", res.data.accessToken);
              let new_user_details = res.data.userDetails;
              new_user_details.company_name = user?.company_name;

              // localStorage.setItem("user", JSON.stringify(new_user_details));
              refreshTokenPromise = null; // reset the promise so it can be used again
              return res.data.accessToken;
            })
            .catch((err) => {
              console.error("🚀 ~ file: api.js:57 ~ err:", err);
              // if(err.response.status===403){
              logout(refreshToken);
              // }
              refreshTokenPromise = null; // reset the promise so it can be used again
              // throw err; // propagate the error to the original request
            });
        }

        // wait for the refresh token promise to be resolved with the new access token
        config.headers.Authorization = `Bearer ${await refreshTokenPromise}`;
      }
    }
    // else if(!accessToken && !refreshToken){
    //   // window.location.reload();
    // }

    return config;
  },
  (error) => {
    console.error("Error to call API", error);
  }
);

let isRefreshing = false;
let refreshTokens = [];

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    Sentry.captureException(error);
    const originalRequest = error.config;
    const { refreshToken } = localStorage;
    let user = localStorage.getItem("user");
    user = user ? JSON.parse(user) : null;
    if (
      (error.response.status === 401 || error.response.status === 403) &&
      !refreshToken &&
      error?.config?.url !== "/api/auth"
    ) {
      return window.location.reload();
    } else if (
      (error.response.status === 401 || error.response.status === 403) &&
      !originalRequest._retry &&
      refreshToken
    ) {
      originalRequest._retry = true;

      if (!isRefreshing) {
        isRefreshing = true;
        try {
          const res = await axios.get("/api/refresh", {
            baseURL: process.env.REACT_APP_BACKEND_URL,
            headers: { refreshToken },
          });
          if (res?.status === 200) {
            localStorage.setItem("accessToken", res.data.accessToken);
            let new_user_details = res.data.userDetails;
            new_user_details.company_name = user?.company_name;
            // localStorage.setItem("user", JSON.stringify(res.data.userDetails));
            api.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${res.data.accessToken}`;

            // resolve all the requests in the refresh token queue
            refreshTokens.forEach((cb) => cb(res.data.accessToken));
            refreshTokens.length = 0;

            // update the original request with the new access token and send it again
            originalRequest.headers[
              "Authorization"
            ] = `Bearer ${res.data.accessToken}`;
            return api(originalRequest);
          } else {
            logout(refreshToken);
            return null;
          }
        } catch (e) {
          console.error(e);
          logout(refreshToken);
          return e;
        } finally {
          isRefreshing = false;
        }
      } else {
        // if the token is being refreshed, add the request to the queue
        return new Promise((resolve) => {
          refreshTokens.push((token) => {
            originalRequest.headers["Authorization"] = `Bearer ${token}`;
            resolve(api(originalRequest));
          });
        });
      }
    }

    return Promise.reject(error);
  }
);

function logout(refreshToken) {
  localStorage.removeItem("user");
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("expireWarning");
  localStorage.removeItem("iframe");
  axios.get("/api/logout", {
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: { refreshToken },
  });

  window.location.reload();
}

export default api;
