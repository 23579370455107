import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  createCallCenters,
  getCallCenters,
  updateCallCenters,
} from "../services/callCenterService";
import {
  addSpamNumbers,
  createAudioFiles,
  createCallMenu,
  deleteInboundGroup,
  deleteSpamNumbers,
  getAgentPerformacne,
  getAudioFiles,
  getCallMenu,
  getCallSummaryReport,
  getSpamNumbers,
  updateCallMenu,
} from "../services/inboundGroupService";
const initialState = {
  isError: false,
  isLoading: false,
  record: [],
  message: "",
  agentPerformance: [],
  callSummary: [],
  audioFiles: [],
  callMenu: [],
  spamNumbers: [],
};
const getErrorMessage = (error) => {
  return (
    error?.response?.data?.error ||
    (error?.response?.data?.debugInfo && [error?.response?.data?.debugInfo]) ||
    error?.response?.data?.data?.message ||
    error?.response?.data?.message ||
    error?.response?.data ||
    error.toString()
  );
};
// Get Inbound Group Data
export const get_call_centers = createAsyncThunk(
  "get_call_centers",
  async (thunkAPI) => {
    try {
      return await getCallCenters();
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Create New Inbound Group
export const create_call_centers = createAsyncThunk(
  "create_call_centers",
  async (data, thunkAPI) => {
    try {
      return await createCallCenters(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update Inbound Group
export const update_call_centers = createAsyncThunk(
  "update_call_centers",
  async (data, thunkAPI) => {
    try {
      return await updateCallCenters(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Delete Inbound Group
export const delete_call_centers = createAsyncThunk(
  "delete_call_centers",
  async (id, thunkAPI) => {
    try {
      return await deleteInboundGroup(id);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get Agent Performance
export const get_agent_performance = createAsyncThunk(
  "get_agent_performance",
  async (data, thunkAPI) => {
    try {
      return await getAgentPerformacne(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const get_call_summary_report = createAsyncThunk(
  "get_call_summary_report",
  async (data, thunkAPI) => {
    try {
      return await getCallSummaryReport(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);


export const get_audio_files = createAsyncThunk(
  "get_audio_files",
  async (data, thunkAPI) => {
    try {
      return await getAudioFiles(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const create_audio_files = createAsyncThunk(
  "create_audio_files",
  async (data, thunkAPI) => {
    try {
      return await createAudioFiles(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);


export const get_call_menu = createAsyncThunk(
  "get_call_menu",
  async (thunkAPI) => {
    try {
      return await getCallMenu();
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const create_call_menu = createAsyncThunk(
  "create_call_menu",
  async (data, thunkAPI) => {
    try {
      return await createCallMenu(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const update_call_menu = createAsyncThunk(
  "update_call_menu",
  async (data, thunkAPI) => {
    try {
      return await updateCallMenu(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const get_spam_numbers = createAsyncThunk(
  "get_spam_numbers",
  async (thunkAPI) => {
    try {
      return await getSpamNumbers();
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const add_spam_numbers = createAsyncThunk(
  "add_spam_numbers",
  async (data, thunkAPI) => {
    try {
      return await addSpamNumbers(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const delete_spam_numbers = createAsyncThunk(
  "delete_spam_numbers",
  async (data, thunkAPI) => {
    try {
      return await deleteSpamNumbers(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const callCenterSlice = createSlice({
  name: "callCenter",
  initialState,
  reducers: {
    callCenterReset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.record = [];
      state.message = "";
      state.agentPerformance = [];
      state.callSummary = [];
      state.spamNumbers=[]
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_call_centers.pending, (state) => {
        state.isLoading = true;
        state.record = [];
      })
      .addCase(get_call_centers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.record = action.payload?.data;
      })
      .addCase(get_call_centers.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.record = [];
        toast.error(action.payload ? action.payload : "Unknown Error");
      })
      .addCase(create_call_centers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(create_call_centers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.record.push(action.payload.data);
      })
      .addCase(create_call_centers.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(update_call_centers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_call_centers.fulfilled, (state, action) => {
        state.isLoading = false;
        const result = state.record.findIndex(
          ({ _id }) => _id === action?.payload?.data?._id
        );
        state.record[result] = {
          ...state.record[result],
          ...action.payload.data,
        };
      })
      .addCase(update_call_centers.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(delete_call_centers.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(get_agent_performance.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_agent_performance.fulfilled, (state, action) => {
        state.isLoading = false;
        state.agentPerformance = action.payload.data?.data;
      })
      .addCase(get_agent_performance.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(get_call_summary_report.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_call_summary_report.fulfilled, (state, action) => {
        state.isLoading = false;
        state.callSummary = action.payload.data;
      })
      .addCase(get_call_summary_report.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(get_audio_files.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_audio_files.fulfilled, (state, action) => {
        state.isLoading = false;
        state.audioFiles = action.payload.data;
      })
      .addCase(get_audio_files.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(create_audio_files.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(create_audio_files.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(create_audio_files.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(create_call_menu.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(create_call_menu.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(create_call_menu.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(get_call_menu.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_call_menu.fulfilled, (state, action) => {
        state.isLoading = false;
        state.callMenu = action.payload.data;
      })
      .addCase(get_call_menu.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(update_call_menu.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_call_menu.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_call_menu.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(add_spam_numbers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(add_spam_numbers.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(add_spam_numbers.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(get_spam_numbers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(get_spam_numbers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.spamNumbers = action.payload.data;
      })
      .addCase(get_spam_numbers.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(delete_spam_numbers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(delete_spam_numbers.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(delete_spam_numbers.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { callCenterReset } = callCenterSlice.actions;
export default callCenterSlice.reducer;
