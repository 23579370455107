import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  createEmailTemplate,
  deleteEmailTemplate,
  getEmailTemplates,
  updateEmailTemplate,
} from "../services/emailTemplateService";

const initialState = {
  isError: false,
  isLoading: false,
  record: [],
  message: "",
};
const getErrorMessage = (error) => {
  return (
    error?.response?.data?.error ||
    (error?.response?.data?.debugInfo && [error?.response?.data?.debugInfo]) ||
    error?.response?.data?.message ||
    error?.response?.data ||
    error.toString()
  );
};
// Get Email Template
export const get_template = createAsyncThunk(
  "get_template",
  async (data, thunkAPI) => {
    try {
      return await getEmailTemplates(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Create New Merchants
export const create_template = createAsyncThunk(
  "create_template",
  async (data, thunkAPI) => {
    try {
      return await createEmailTemplate(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update Merchants
export const update_template = createAsyncThunk(
  "update_template",
  async (data, thunkAPI) => {
    try {
      return await updateEmailTemplate(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Delete Merchants
export const delete_template = createAsyncThunk(
  "delete_template",
  async (id, thunkAPI) => {
    try {
      return await deleteEmailTemplate(id);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const emailTemplateSlice = createSlice({
  name: "emailTemplate",
  initialState,
  reducers: {
    emailTemplateReset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.record = [];
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_template.pending, (state) => {
        state.isLoading = true;
        // state.record = [];
      })
      .addCase(get_template.fulfilled, (state, action) => {
        state.isLoading = false;
        state.record = action.payload?.data;
      })
      .addCase(get_template.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        // state.record = [];
        toast.error(action.payload ? action.payload : "Unknown Error");
      })
      .addCase(create_template.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(create_template.fulfilled, (state, action) => {
        state.isLoading = false;
        state.record?.records?.push(action.payload.data);
      })
      .addCase(create_template.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_template.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_template.fulfilled, (state, action) => {
        state.isLoading = false;
        const result = state.record?.records?.findIndex(
          ({ _id }) => _id === action?.payload?.data?._id
        );
        state.record.records[result] = {
          ...state.record?.records[result],
          ...action.payload.data,
        };
      })
      .addCase(update_template.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(delete_template.fulfilled, (state, action) => {
        const result = state.record?.records?.findIndex(
          ({ _id }) => _id === action?.payload?.data?._id
        );
        state.record.records[result].active = false;
      });
  },
});

export const { emailTemplateReset } = emailTemplateSlice.actions;
export default emailTemplateSlice.reducer;
