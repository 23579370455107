import React from "react";

import {
  DotsLoader,
  FormInput,
  FormSelectInput,
  Modal,
} from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { create_role, update_role } from "../../features/rolesSlice";
import { toast } from "react-toastify";
import errorMessage from "../../util/errorMessage";

const RoleForm = ({ editingRecord, onCancelForm, modalTitle }) => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.roles);
  const [errors, setErrors] = React.useState([]);
  let initialValues = {
    name: "",
    level: null,
    status: true,
  };

  if (editingRecord) {
    const { _id, name, level, status } = editingRecord;
    initialValues = {
      id: _id,
      name,
      level,
      status,
    };
  }
  const formik = useFormik({
    initialValues,

    validateOnBlur: true,

    onSubmit: async (values) => {
      if (!editingRecord) {
        try {
          const res = await dispatch(create_role(values));
          if (res?.payload?.status === 200) {
            toast.success("Role created");
            onCancelForm();
          } else {
            errorMessage({
              payload: res.payload,
              setErrors: setErrors,
              action: "Roles",
              msg: "created",
            });
          }
        } catch (error) {
          toast.error("Role couldn't be created");
        }
      } else {
        try {
          const res = await dispatch(update_role(values));
          if (res?.payload?.status === 200) {
            toast.success("Role updated");
            onCancelForm();
          } else {
            errorMessage({
              payload: res.payload,
              setErrors: setErrors,
              action: "Roles",
              msg: "updated",
            });
          }
        } catch (error) {
          toast.error("Role couldn't be updated");
        }
      }
    },
  });

  return (
    <>
      <Modal
        isUpdate={!!editingRecord}
        title={modalTitle}
        onCancelModal={onCancelForm}
        formIsValid={formik.isValid}
        onSubmit={formik.handleSubmit}
        isLoading={isLoading}
        onClick={onCancelForm}
      >
        {isLoading && <DotsLoader />}
        <form>
          <div className="!mb-3">
            <FormInput
              errors={errors}
              name="name"
              label="Name"
              formik={formik}
            />
          </div>
          <div className="!mb-3">
            <FormInput
              errors={errors}
              name="level"
              label="Level"
              formik={formik}
            />
          </div>
          <div className="!mb-3">
            <FormSelectInput
              errors={errors}
              name="status"
              label="Status"
              formik={formik}
              options={[
                { label: "Active", value: true },
                { label: "InActive", value: false },
              ]}
              valueProp="value"
              labelProp="label"
            />
          </div>
        </form>
      </Modal>
    </>
  );
};

export default RoleForm;
