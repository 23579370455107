import api from "./api";

export const getLeadAction = async () => {
  return await api.get("/api/leadAction");
};
export const createLeadAction = async (data) => {
  return await api.post("/api/leadAction", data);
};

export const updateLeadAction = async (data) => {
  return await api.put(`/api/leadAction/${data?.id}`, data);
};

export const deleteLeadAction = async (id) => {
  return await api.delete(`/api/leadAction/${id}`);
};
