import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  createInboundGroup,
  deleteInboundGroup,
  getInboundGroup,
  getInboundGroupDrd,
  updateInboundGroup,
} from "../services/inboundGroupService";
const initialState = {
  isError: false,
  isLoading: false,
  record: [],
  message: "",
  inboundDrd:[]
};
const getErrorMessage = (error) => {
  return (
    error?.response?.data?.error || 
    (error?.response?.data?.debugInfo&&[error?.response?.data?.debugInfo]) ||
    error?.response?.data?.message ||
    error?.response?.data ||
    error.toString()
  );
};
// Get Inbound Group Data
export const get_inbound_group = createAsyncThunk(
  "get_inbound_group",
  async (id, thunkAPI) => {
    try {
      return await getInboundGroup();
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Get Inbound Group Drd
export const get_inbound_group_drd = createAsyncThunk(
  "get_inbound_group_drd",
  async (id, thunkAPI) => {
    try {
      return await getInboundGroupDrd();
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Create New Inbound Group
export const create_inbound_group = createAsyncThunk(
  "create_inbound_group",
  async (data, thunkAPI) => {
    try {
      return await createInboundGroup(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update Inbound Group
export const update_inbound_group = createAsyncThunk(
  "update_inbound_group",
  async (data, thunkAPI) => {
    try {
      return await updateInboundGroup(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Delete Inbound Group
export const delete_inbound_group = createAsyncThunk(
  "delete_inbound_group",
  async (id, thunkAPI) => {
    try {
      return await deleteInboundGroup(id);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const inboundGroupSlice = createSlice({
  name: "inboundGroup",
  initialState,
  reducers: {
    inboundReset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.record = [];
      state.inboundDrd=[];
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_inbound_group.pending, (state) => {
        state.isLoading = true;
        state.record = [];
      })
      .addCase(get_inbound_group.fulfilled, (state, action) => {
        state.isLoading = false;
        state.record = action.payload?.data;
      })
      .addCase(get_inbound_group.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.record = [];
        toast.error(action.payload?action.payload:"Unknown Error")
      })
      .addCase(get_inbound_group_drd.pending, (state) => {
        state.isLoading = true;
        state.inboundDrd = [];
      })
      .addCase(get_inbound_group_drd.fulfilled, (state, action) => {
        state.isLoading = false;
        state.inboundDrd = action.payload?.data;
      })
      .addCase(get_inbound_group_drd.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.inboundDrd = [];
        toast.error(action.payload?action.payload:"Unknown Error")
      })
      .addCase(create_inbound_group.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(create_inbound_group.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(create_inbound_group.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_inbound_group.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_inbound_group.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_inbound_group.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(delete_inbound_group.fulfilled, (state, action) => {
        state.isLoading = false;
      });
  },
});

export const { inboundReset } = inboundGroupSlice.actions;
export default inboundGroupSlice.reducer;
